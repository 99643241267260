import React from 'react';
import styled from 'styled-components';
import theme from '../theme'
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types'
import kebabCase from "lodash/kebabCase"
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Tags from '../components/Tags';
import Block from "../components/Block";
import Subscribe from "../components/Subscribe";
import ShareButtons from '../components/ShareButtons';
import IconDown from '../images/icons/link.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const BlogPostTemplate = ({ data, location }) => {
	const post = data.markdownRemark;
	const cover = post.frontmatter.showCoverImage ? getImage(post.frontmatter.meta.image) : '';
	const url = typeof window !== 'undefined' ? window.location.href : '';
	const imgAlt = cover ? post.frontmatter.meta.imgAlt : '';
	const group = post.frontmatter.tags;
	const title = post.frontmatter?.meta?.SEOtitle || post.frontmatter.title
	const description = post.frontmatter?.meta?.SEOdescription || post.frontmatter.description
	const { previous, next } = data;
	const { gitAuthorTime } = post.fields

	return (
		<>
			<Layout location={location} title={post.frontmatter.title + " | Roman Musatkin"}>
				<Seo
					title={title}
					description={description || post.excerpt}
				/>
				<Block size='small'>
					<article
						className="blog-post"
						itemScope
						itemType="http://schema.org/Article"
					>
						<div className="blog-post__header">
							<Tags>
								<ul>
									{group.map(tag => (
										<li key={tag}>
											<Link to={`/tags/${kebabCase(tag)}/`}>
											#{tag}
											</Link>
										</li>
									))}
								</ul>
							</Tags>
							<h1 itemProp="headline">{post.frontmatter.title}</h1>
							<HeaderBottom>
								<ShareButtons url={url} title={post.frontmatter.title} />
								<p className="label"><a href="/roman/">{post.frontmatter.author}</a><span className="mobile-only"><br/></span> on {post.frontmatter.date}</p>
							</HeaderBottom>			
						</div>
						<CoverImageWrapper>
							{cover && post.frontmatter.showCoverImage && (
								<GatsbyImage image={cover} className="coverImage" alt={post.frontmatter.meta.imgAlt} />
							)}
							{imgAlt && (
								<ImgAltWrapper>{imgAlt}</ImgAltWrapper>
							)}
						</CoverImageWrapper>
						<section
							dangerouslySetInnerHTML={{ __html: post.html }}
							itemProp="articleBody"
							className="articleBody"
						/>
						{post.frontmatter.showUpdated && (
							<p className="label">Updated on {gitAuthorTime}</p>
						)}
					</article>
				</Block>
				<ShareSection>
					<IconUp/>
					<h5>Share this post</h5>
					<ShareButtons url={url} title={post.frontmatter.title} />
					<img src={IconDown} className="icondown"/>
				</ShareSection>
				<Subscribe/>
				<Block>
					<nav className="blog-post-nav">
						<ul>
							<li>
								{next && (
									<Link to={next.fields.slug} rel="prev">
									←<br/><br/>
										{' '}
										<h3>{next.frontmatter.title}</h3>
									</Link>
								)}
							</li>
							<li>
								{previous && (
									<Link to={previous.fields.slug} rel="next">
									→<br/><br/>
										<h3>{previous.frontmatter.title}</h3>
										{' '}
									</Link>
								)}
							</li>
						</ul>
					</nav>
				</Block>
			</Layout>
		</>
	);
};

export default BlogPostTemplate;

BlogPostTemplate.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
			group: PropTypes.arrayOf(
				PropTypes.shape({
					tags: PropTypes.string.isRequired
				}).isRequired
			),
		}),
	}),
}

const IconUp = styled.div`
	content:' ';
	width: 1px;
	height: 40px;
	margin: 0 auto;

	background: linear-gradient(180deg, rgba(71, 145, 255, 0.28) 0%, #4791FF 98.44%);
	border-radius: 12px;
`

const HeaderBottom = styled.div`
	margin-top:3rem;
	margin-bottom:1.5rem;
    display: flex;
    justify-content: space-between;
	align-items: center;

	.label {
		align-self: right;
		margin-bottom:0;
	}
`

export const CoverImageWrapper = styled.div`
	margin-top: var(--spacing-4);
	margin-bottom: var(--spacing-12);
`

export const ImgAltWrapper = styled.div`
	font-family: ${theme.fonts.sans};
	margin-top: ${theme.space[1]};
	color: ${theme.colors.grayLabel};
	font-size: ${theme.fontSizes[1]};
`

const ShareSection = styled.div`
	position:relative;
	padding:var(--spacing-6) var(--spacing-8);
    margin: var(--spacing-4) 0 0;
	text-align:center;

	h5 {
		font-size:1rem;
		position:relative;
	}

	.icondown {
		margin-top:12px;
	}
`

const BlogPostFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
    align-items: stretch;
	margin:64px auto 0; 
	border-top: 1px #eaeaea solid;
	padding:64px 0;

	.blog-post-footer__avatar {
		width: 64px;
		flex: 0 0 64px;
		height: 64px;
		margin-right: 24px;
	}
`;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMM D, YYYY")
        description
		tags
		showCoverImage
		showUpdated
		author
		meta {
			image {
				childImageSharp {
				gatsbyImageData(width: 2400, quality: 100)
				original {
					src
					width
					height
					}
				}
			}
			SEOtitle
			SEOdescription
			imgAlt
		}
      }
	  fields {
		gitAuthorTime(formatString: "MMM D, YYYY")
	  }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
