import React from 'react';
import FacebookIcon from '../images/icons/facebook-share.svg'
import TwitterIcon from '../images/icons/twitter-share.svg'
import styled from 'styled-components';
import {
	FacebookShareButton,
	TwitterShareButton,
} from 'react-share';

const ShareButtons = ({ url, title }) => (
	<ShareButtonsContainer>
		<TwitterShareButton url={url} title={title} className='button'>
			<img src={TwitterIcon} alt="Share on Twitter" placeholder="dominantColor"/>
		</TwitterShareButton>
		<FacebookShareButton url={url} quote={title} className='button'>
			<img src={FacebookIcon} alt="Share on Facebook" placeholder="dominantColor"/>
		</FacebookShareButton>
	</ShareButtonsContainer>
);

export default ShareButtons

const ShareButtonsContainer = styled.div`
	display: inherit;
	
	.button {
		margin:0px 6px;
		height:32px;
		display:inline-block;

		img {
			display:inline-block;
			height:32px;
			margin:0;
		}
	}

	& > .button:hover {
		transform: scale3d(.95,.95,.95);
	}
`