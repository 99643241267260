import React from 'react';
import styled from 'styled-components';
import theme from '../theme';
import Block from '../components/Block'

export const Subscribe = () => (
	<FormContainer>
		<Block size='small'>
			<h3>Selected stories on culture, languages, education and design.</h3>
			<form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
				<input type="hidden" name="bot-field" />
				<input type="hidden" name="form-name" value="contact" />
				<input type="email" placeholder='Email' name="email" id="email" />
				<button type="submit">Subscribe</button>
			</form>
		</Block>
	</FormContainer>
)

export default Subscribe

const FormContainer = styled.div`
	padding:var(--spacing-4) 0 var(--spacing-6);
    margin: 0 0 var(--spacing-4);
	text-align:center;
	display:flex;

	h3 {
		max-width:400px;
		line-height:1.3;
		margin-bottom:2rem;
		margin-top:0;
	}

	input, button {
		display:inline-block;
		width:100%;
		max-width:400px;
		height:48px;
		outline:none;
		line-height:40px;
		border:none;
		margin-bottom:12px;
		font-family: ${theme.fonts.monospace};
		transition: background-color .2s ease-in-out, color .2s ease-in-out, opacity .2s ease-in-out;
    	transition: all .15s ease-out;
		border-radius: ${theme.radii.medium};
	}

	input {
		background:#EDEDED;
		font-size:1.5rem;
		display:block;

		&:focus {
			background: #F4F4F4;
		}
	}

	button {
		background:${theme.colors.purple};
		color:white;
		font-size:1.5rem;
		cursor:pointer;

		&:hover {
			transform: scale3d(.98,.98,.98);
			background:#704988;
		}
	}

	input {
		text-align:center;
	}
`;